@font-face {
  font-family: Apercu;
  font-style: normal;
  font-weight: normal;
  src: url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Regular.eot");
  src:
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Regular.woff2")
      format("woff2"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Regular.woff")
      format("woff"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Regular.ttf")
      format("truetype"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Regular.svg")
      format("svg");
}

@font-face {
  font-family: Apercu;
  font-style: normal;
  font-weight: bold;
  src: url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Medium.eot");
  src:
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Medium.woff2")
      format("woff2"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Medium.woff")
      format("woff"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Medium.ttf")
      format("truetype"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Medium.svg")
      format("svg");
}

@font-face {
  font-family: Apercu;
  font-style: normal;
  font-weight: 300;
  src: url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Light.eot");
  src:
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Light.woff2")
      format("woff2"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Light.woff")
      format("woff"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Light.ttf")
      format("truetype"),
    url("https://s3.eu-west-2.amazonaws.com/resident.ly-production-static-assets/Apercu-Light.svg")
      format("svg");
}

:root {
  font-size: 10px;
} /* font-size 1em = 10px on default browser settings */

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
